\:root
  --dark-grey: #686868
  --medium-dark-grey: #c7c7c7
  --soft-grey: #fbfbf9
  --grey-light: #f2f2eb
  --medium-grey: #6d6d6d
  --medium-grey-two: #999999
  --medium-grey-three: #a6a697
  --medium-grey-icon: #a29a7f
  --grey-light-icon: #bab4a0
  --grey: #d9d5c5
  --gray: #727272
  --white: #ffffff
  --light-black: #444f5f
  --medium-black: #5d5c5c
  --black: #000000
  --dark-green: #003b1a
  --dark-green-two: #013918
  --dark-green-three: #326200
  --green-title: #125b2c
  --light-green: #b6da47
  --green-light-text: #b8dc4a
  --medium-green: #84bf04
  --accent-green: #a4ca2b
  --green: #90ca35
  --green-active-link: #a3c92b
  --green-pass: #B1D63F
  --red: #f15a24
  --red-two: #ff5722
  --red-error: #FF0000
  --red-cross: #f44336
  --red-info: #9C0000
  --orange: #f26716
  --medium-orange: #da6b36
  --light-yellow: #fff8cc
  --yellow: #ffd900
  --blue: #b0e1e3
  --dark-blue: #196674
  --light-brown: #d93d04
  --brown: #8c4126
  --beige: #f1f1ea
  --beige-active-background: #e6e5dc
  --green-background-success: #f6fae9
  --red-background-error: #f7d1c6