.mat-form-field {
  width: 100%;
}
.mat-form-field-underline {
  display: none;
}
.mat-form-field-infix {
  padding: 0 !important;
  margin: 0px;
  border-top-width: 0px;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px;
}
.mat-error {
  font-size: 0.75em;
}
input.mat-input-element {
  border: 1px solid var(--grey);
  margin-top: -0.0625em;
  padding-left: 0.5em;
  width: calc(100% - 0.5em);
  background: var(--white);
  height: 40px;
}

mat-label {
  padding: 5px 0px;
  display: flex;
  font-size: 0.75em;
}
.mat-icon.active {
  background: var(--grey-light);
  border-radius: 10px;
  padding: 10px 15px;
  margin: 0px 5px;
}
.mat-icon.active.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: 7px !important;
  bottom: 5px !important;
}

.ao-required {
  color: var(--red-error);
  font-weight: bold;
  margin-left: 3px;
  font-size: 1em;
}
.mat-icon > .ao-required {
  position: absolute;
}
.mat-icon:has(.ao-required) {
  position: relative;
}

.ao-green .mat-select-panel {
  background: white;
}
