@import mixins
@import variables

.admin
    &-wrapper
        +app-font-regular()
        max-width: 1200px
        margin: 0 auto
        padding: 0 1em
    &-title
        font-weight: normal
        text-align: center
        margin: 10px 0
        font-size: 24px
    input
        font-family: inherit
        font-size: inherit
    table
        $gray: #CCC
        +app-font-regular()
        width: 100%
        min-width: 50em
        border: 1px solid $gray
        border-collapse: collapse
        font-weight: normal !important
        th, td
            width: auto
            border: 1px solid $gray
            padding: 0.25em 0.3em
        tr
            &:nth-child(even)
                background: #e8eff1
        .right
            text-align: right
        .number
            text-align: right
            +app-font-proit()
        .stripped
            border-right: none
            border-bottom: none
            border-top: none
        .actions
            text-align: center
            width: 150px
            button, .btn
                width: 22px
                height: 22px
                vertical-align: middle
                font-size: 1.1em
                display: inline-flex
                flex-flow: row wrap
                align-items: center
                justify-content: center
                margin-right: 0.33em
                text-align: center
                img, .pi
                    margin-right: 0 !important
                &:last-child
                    margin-right: 0

.status-light
  width: 20px
  height: 20px
  border-radius: 50%
  margin: 0 auto
  box-shadow: 0 0 5px 2px rgba(200, 200, 250, .3)
  &.active
    background: radial-gradient(circle at 40% 40%, $light-green, $green)
  &.inactive
    background: radial-gradient(circle at 40% 40%, $red, $danger)