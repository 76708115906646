@import './_colors.sass';
@import './_flex.sass';
@import './variables.scss';
@import './custom-material.scss';
@import './_custom-mat-dialog.scss';
@import './custom-ng-select.scss';
@import './modals.sass';

\:host {
  display: block;

  .mat-icon {
    line-height: 30px;
    height: 30px;
    width: 30px;
    padding: 0;
    min-width: 0;
    flex-shrink: 0;
    border-radius: 50%;
    padding-top: 5px;
  }
}

button {
  color: inherit;
  background: transparent;
  border: none;
}

fieldset {
  border-width: 1px;
  border-color: var(--grey);
}

.c-p {
  cursor: pointer;
}

.is-enabled {
  color: var(--green);
}

.is-disabled {
  color: var(--grey);
}
