@import variables

.background-admin
  background-color: map-get($zone-colors, admin)
.background-appro
  background-color: map-get($zone-colors, appro)
.background-factories
  background-color: map-get($zone-colors, factories)
.background-offer
  background-color: map-get($zone-colors, offer)
.background-pc-radio
  background-color: map-get($zone-colors, pc-radio)
.background-planification
  background-color: map-get($zone-colors, planification)
.background-priorisation
  background-color: map-get($zone-colors, priorisation)
.background-sampling
  background-color: map-get($zone-colors, sampling)
.background-simulation
  background-color: map-get($zone-colors, simulation)
.background-trucks
  background-color: map-get($zone-colors, trucks)
