/* Variables */
$yellow: #FBC503
$light-yellow: #fcd453
$dark-grey: #333333
$grey: #666666
$marble-grey: #6A6C6D
$middle-grey: #999999
$light-grey: #cacaca
$black: #333333
$green: #8cc63f
$light-green: #E3E890
$dark-green: #c5cf00
$gold: #6B5402
$danger: #e91224
$red: #EF7159
$blue: #67C6E8
$light-blue: #b3d7e2
$purple: #bb7ee8
$dark-purple: #A95BE2
$light-purple: #dbc4ed

$sizeDayTimelinePlanification: 480px
$sizeDayTimelinePcRadio: 1440px

$zone-colors: (admin: #67c6e8, appro: #c5cf00, factories: #f15a24, offer: #8cc63f, pc-radio: #c5cf00, planification: #fbc503, priorisation: #8bd1d4, sampling: #e2d9a1, simulation: #bb7ee8, trucks: #fbc504)

$media-max-width: 1300px

/* Material Breakpoints */
$media-xs-max-width: 599px
$media-sm-min-width: 600px
$media-sm-max-width: 899px
$media-md-min-width: 900px
$media-md-max-width: 1199px
$media-lg-min-width: 1200px
$media-lg-max-width: 1535px
$media-xl-min-width: 1536px
