@font-face
  font-family: 'Myriad'
  src: url('assets/fonts/Myriad/Myriad-Pro-Regular.ttf')
@font-face
  font-family: 'Myriad-Bold'
  src: url('assets/fonts/Myriad/Myriad-Pro-Bold-Condensed.ttf')
  font-weight: bold
@font-face
  font-family: 'Myriad-SemiBold'
  src: url('assets/fonts/Myriad/Myriad-Pro-Semibold.otf')
@font-face
  font-family: 'Myriad-Black'
  src: url('assets/fonts/Myriad/Myriad-Pro-Black.otf')
@font-face
  font-family: 'Myriad-Light'
  src: url('assets/fonts/Myriad/Myriad-Pro-Light.otf')
@font-face
  font-family: 'Myriad-Con'
  src: url('assets/fonts/Myriad/Myriad-Pro-Condensed.ttf')