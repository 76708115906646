.disp-f
  display: flex

.disp-g
  display: grid

.j-c-c
  justify-content: center

.a-i-c
  align-items: center

.a-i-fe
  align-items: flex-end

.a-i-e
  align-items: end

.j-c-sb
  justify-content: space-between

.a-s-fe
  align-self: flex-end

.f-shrink-0
  flex-shrink: 0

.f-dir-c
  flex-direction: column

.f-b-40
  flex-basis: 40%

.f-b-49
  flex-basis: 49%

.f-b-80
  flex-basis: 80%