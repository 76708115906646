@tailwind base;
@tailwind components;
@tailwind utilities;

$sizes: (
  xxs: 4px,
  xs: 8px,
  s: 12px,
  m: 16px,
  l: 24px,
  xl: 32px,
  xxl: 48px,
);

@mixin font-size-classes($sizes) {
  @each $key, $value in $sizes {
    .fs-#{$key} {
      font-size: $value;
    }
  }
}

@mixin margin-classes($sizes) {
  @each $key, $value in $sizes {
    .m-#{$key} {
      margin: $value;
    }
  }
}

@mixin margin-left-classes($sizes) {
  @each $key, $value in $sizes {
    .ml-#{$key} {
      margin-left: $value;
    }
  }

  .ml-a {
    margin-left: auto;
  }
}

@mixin margin-right-classes($sizes) {
  @each $key, $value in $sizes {
    .mr-#{$key} {
      margin-right: $value;
    }
  }

  .mr-0 {
    margin-right: 0;
  }
}

@mixin margin-top-classes($sizes) {
  @each $key, $value in $sizes {
    .mt-#{$key} {
      margin-top: $value;
    }
  }
}

@mixin margin-bottom-classes($sizes) {
  @each $key, $value in $sizes {
    .mb-#{$key} {
      margin-bottom: $value;
    }
  }
}

// Mixin for padding
@mixin padding($direction, $size, $value) {
  .p#{$direction}-#{$size} {
    padding#{$direction}: $value;
  }
}

@mixin padding-left-classes($sizes) {
  @each $key, $value in $sizes {
    .pl-#{$key} {
      padding-left: $value;
    }
  }
}

@mixin padding-right-classes($sizes) {
  @each $key, $value in $sizes {
    .pr-#{$key} {
      padding-right: $value;
    }
  }
}

@mixin padding-top-classes($sizes) {
  @each $key, $value in $sizes {
    .pt-#{$key} {
      padding-top: $value;
    }
  }
}

@mixin padding-bottom-classes($sizes) {
  @each $key, $value in $sizes {
    .pb-#{$key} {
      padding-bottom: $value;
    }
  }
}

// Generate margin classes for all directions
@include margin-classes($sizes);
@include margin-left-classes($sizes);
@include margin-right-classes($sizes);
@include margin-top-classes($sizes);
@include margin-bottom-classes($sizes);

// Generate padding classes for all directions
@each $size, $value in $sizes {
  @include padding('', $size, $value);
}
@include padding-left-classes($sizes);
@include padding-right-classes($sizes);
@include padding-top-classes($sizes);
@include padding-bottom-classes($sizes);

@layer utilities {
  .disabled-field {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
    background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
    color: var(--fallback-bc, oklch(var(--bc) / 0.4));
  }
}
