@import mixins
@import variables

.stocks
    &__label
        text-transform: uppercase
        font-weight: normal
        letter-spacing: 0.1em
        font-size: 1.4em
        padding: 0
        +app-font-light()
        