.ng-select .ng-select-container {
  min-height: 40px !important;
}

.ng-select .ng-value-container {
  flex-wrap: wrap;
  min-width: 0;

  .ng-value {
    white-space: nowrap;
    min-width: 0;
    display: flex;
    max-width: 95%;

    .ng-value-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

ng-select input:read-only {
  opacity: 1;
  border: none !important;
  background-color: transparent;
}

ng-select input[type='text'] {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5rem;
}

.required-error {
  border-color: var(--red-error) !important;

  &-auto {
    border: 1px solid var(--red-error) !important;
  }
}

.pass-required {
  border-color: var(--green-pass) !important;

  &-auto {
    border: 1px solid var(--green-pass) !important;
  }
}

.ng-select.disabled-field {
  .ng-select-container {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity))) !important;
    background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity))) !important;
    color: var(--fallback-bc, oklch(var(--bc) / 0.4));
  }
}
