/* Import library functions for theme creation. */
@import '@angular/material/theming';

/* Include non-theme styles for core. */
@include mat-core();

$custom-theme-primary: mat-palette($mat-gray);
$custom-theme-accent: mat-palette($mat-gray);
$custom-theme-warn: mat-palette($mat-gray);

$custom-theme: mat-light-theme(
  (
    color: (
      primary: $custom-theme-primary,
      accent: $custom-theme-accent,
      warn: $custom-theme-warn,
    ),
    typography: mat-typography-config(),
    density: 0,
  )
);

@include angular-material-theme($custom-theme);

.mat-mdc-form-field .mat-mdc-form-field-flex {
  background-color: white;
  margin-top: 1px;
  color: black;
}

.cdk-overlay-container {
  z-index: 1002;
}
