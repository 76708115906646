/* Mixins */
=app-font-regular()
  font-family: Myriad, sans-serif
=app-font-semibold()
  font-family: Myriad-Semibold, sans-serif
=app-font-bold()
  font-family: Myriad-Bold, sans-serif
=app-font-con()
  font-family: Myriad-Con, sans-serif
=app-font-light()
  font-family: Myriad-Light, sans-serif
=app-font-lightpro()
  font-family: MyriadPro-Light, sans-serif
=app-font-black()
  font-family: Myriad-Black, sans-serif
=app-font-semiboldcond()
  font-family: Myriad-SemiboldCond, sans-serif
=app-font-proit()
  font-family: MyriadPro It, sans-serif