.ao-dialog {
  .mdc-dialog__title {
    display: flex;
    align-items: center;
    background-color: var(--light-green);
    height: 50px;
    margin: 0.2rem;
    padding: 1rem;
    font-weight: 600 !important;
    overflow: none;
    margin-bottom: 1rem;
  }
  .actions {
    overflow: none;
    margin: 0.2rem;
    display: flex;
    justify-content: center;
    background-color: var(--beige);

    &-submit {
      text-transform: uppercase;
      width: 6rem;
      font-weight: 600;
      box-shadow: none;
      border: none;
      margin-right: 1rem;
      height: 40px;
      &-active {
        background-color: var(--light-green);
        color: var(--white) !important;
        cursor: pointer;
      }
      &-disabled {
        background: var(--grey);
        opacity: 0.8;
        pointer-events: none;
      }
    }

    &-dismiss {
      background-color: var(--gray);
      color: var(--white) !important;
      border-radius: 0;
      border-color: var(--gray);
    }
  }
}
