.modal-box
  padding: 0
  .modal-title
    position: sticky
    top: 0
    z-index: 999999999
    padding-top: 1.5rem
    padding-bottom: 0.5rem
    background-color: var(--fallback-b1,oklch(var(--b1)/var(--tw-bg-opacity)))
    border-bottom: 1px solid var(--md-light-cream)
    padding-right: 1.5rem
    padding-left: 1.5rem
    margin-bottom: 16px
  .modal-content
    padding-left: 1.5rem
    padding-right: 1.5rem
  .modal-action
    position: sticky
    bottom: 0
    z-index: 2
    padding-top: 0.5rem
    padding-bottom: 1.5rem
    padding-right: 1.5rem
    padding-left: 1.5rem
    border-top: 1px solid var(--md-light-cream)
    margin-top: 16px
    background-color: var(--fallback-b1,oklch(var(--b1)/var(--tw-bg-opacity)))

.p-dialog
  border: none !important
  box-shadow: none !important
  max-height: calc(100vh - 5em)

  .p-dialog-content
    background: transparent !important
    padding: 0 !important
    margin: 0 !important
    display: grid
    overflow: hidden

    .modal-box
      min-width: 480px
      width: 100%
      --tw-scale-x: 1
      --tw-scale-y: 1
  
.ng-dropdown-panel
  z-index: 9999999 !important